import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import ComputingBanner from '../ComputingBanner/ComputingBanner'
import ComputingAbout from '../ComputingAbout/ComputingAbout'
import ComputingService from '../ComputingService/ComputingService'

function Computing() {
  return (
    <>
    <div className="wrapper">
      <Navbar/>
      <ComputingBanner/>
     <ComputingAbout/>
     <ComputingService/>
      <Footer/>
    </div>
    </>
  )
}

export default Computing