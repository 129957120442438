import React, { useEffect, useState } from 'react'
import './Blogbanner.css'
import 'animate.css';
import 'animate.css/animate.min.css';


function Blogbanner() {
    const [animate , setAnimate] = useState(false)

    useEffect(()=>{
    setAnimate(true);
    }, [])
  return (
    <>
      <div className="container-fluid p-0 sectionblg">
    <div className="row ">
      <div className="bannerblg">
        <div className="col-md-9">
          <div  className={`contentblg ${animate ? 'animate__animated animate__slideInRight' : ''}`}>
            <h1><span>Informed Minds, Inspired Lives</span><br/>News and blogs with Depth</h1>
        </div>
        </div>
        <div className="barblg"></div>
       
    </div>
  </div>
</div>

    </>
  )
}

export default Blogbanner