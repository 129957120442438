import React, { useEffect } from 'react'
import '../OdooImplementation.css'
import './deployment.css'
import AOS from 'aos';
import 'aos/dist/aos.css';


function DeploymentSection() {
  useEffect(() => {
    
    AOS.init({});
  }, []);
  return (
    <>

    <div className="deploymentContent_section">
    <ul className="list-group deployment">
      <li className="list-group-item gruop" data-aos="fade-up-right">
          <div className="deployment_content">
            <h3>Data migration</h3>
            <p>
              Data migration requires careful planning before its execution. This process depends on customer data to be imported and the 
              database changes to be made. Based on these two factors our team performs a data migration process. Odoo migration involves 
              transferring data from an existing Odoo installation to a new version. This process ensures that your system remains functional, 
              and all data is intact during the upgrade. Our developers handle this task to launch it for production. Data migration is crucial 
              for maintaining business continuity and ensuring a seamless transition to the latest Odoo version. </p>
              <p id="">We also undertake migration activities where clients are switching from a non-Odoo-based system to Odoo. Such 
                migration is always a bit complicated as it involves ascertaining how to get the data transferred from the existing database 
                to an Odoo Database. It necessitates complex testing and following particular standards. Our team would also identify and 
                implement any customization requirements that arise from the existing system over to the Odoo system.</p>
          </div>
          <div><img src="assets/images/1.data migration.png" alt=""/></div>
      </li>
      <li className="list-group-item gruop" data-aos="fade-up-right">
        <div className="deployment_content">
          <h3>Specific development</h3>
          <p>Sometimes, a specific development request from a customer to satisfy certain business requirements may or may not be feasible. 
            Our developers will respond to all requests and provide their expertise as to the feasibility. </p> 
        </div>
        <div><img src="assets/images/2.spec_development.png" alt=""/></div>
      </li>
      <li className="list-group-item gruop" data-aos="fade-up-right">
        <div className="deployment_content">
          <h3>End-user training and support </h3>
          <p>
            Our team of skilled developers educates our end-users (customers) about how to use the product. End-user training is always conducted with the final product on a staging server so that users have a more hands-on approach to the system. This is also done through recorded videos and tutorials. We also provide 
            support services through Jira for any issues or bugs raised while using the product. </p>
        </div>
        <div><img src="assets/images/3.training&support.png " alt=""/></div>
      </li>
      <li className="list-group-item gruop" data-aos="fade-up-right">
        <div className="deployment_content">
          <h3>Go-Live</h3>
          <p>
            In Go Live, the software is launched and made available to our client’s customers for use. We provide local support during business hours depending on how complex the project 
            is. Go-live support is provided for 1 to 2 weeks under the supervision of a dedicated project manager. </p>
        </div>
        <div><img src="assets/images/4.go_live.png" alt=""/></div>
      </li>
      
    </ul>
</div>

    </>
  )
}

export default DeploymentSection