import React, { useEffect, useState } from 'react'
import './CloudBanner.css'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import 'animate.css';
import 'animate.css/animate.min.css';
import useForm from '../Foms/Form'
import Reloader from '../PReloader/Reloader';

function CloudBanner() {
  const [animate, setAnimate] = useState(false)
  const { formData, formErrors, loading, handleInputChange, handleSubmit } = useForm();

  useEffect(() => {
    setAnimate(true);
  }, [])
  return (
    <>
      <div className="container-fluid p-0 section6">
        <div className="row ">
          <div className="banner6">
            <div className="col-md-8 ">
              <div className="main-content6">
                <div className="side-bar6">
                  <div className="linebar1"></div>
                  <div className="circle6">
                    <div className="black-circle6"></div>
                  </div>
                  <div className="linebar2"></div>
                </div>
                <div className="content6" >
                  <h1 className={` ${animate ? 'animate__animated animate__slideInRight' : ''}`}>Guarding Your Digital Fortunes, <br /> Shielding Your Future</h1>
                  {/* <h5>Secure Your 30% Discount on Odoo <br/>
                  Implementation Today!</h5> */}
                  <p>Comprehensive protection against digital threats,
                    ensuring your business stays secure in an increasingly
                    interconnected world. We specialize in safeguarding
                    your critical assets, with</p>

                  <ul className={`list6 ${animate ? 'animate__animated animate__bounceInLeft' : ''}`}>
                    <li><img src="assets/images/tick2.png" alt="" />Proactive monitoring</li>
                    <li><img src="assets/images/tick2.png" alt="" />Advanced threat detection</li>
                    <li><img src="assets/images/tick2.png" alt="" />Rapid incident response</li>
                  </ul>
                </div>
              </div>

              {/* <div className="bar6"></div> */}
            </div>

            <div className="">
              <div className="heroForm6">
                {loading ? (
                  <Reloader />
                ) : (
                  <form onSubmit={handleSubmit}  className="form6">
                    <h6>Transform Your Business Now</h6>
                    <div className="input-box6">
                    <input type="text" required name="name" value={formData.name} onChange={handleInputChange} id="input-field" />
                      <span>Full Name</span>
                      <div><img src="assets/images/user.png" alt="" width="20px" /></div>
                    </div>
                    <div className="input-box6">
                      <input
                        type="text"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Email</span>
                      <div><img src="assets/images/email.png" alt="" width="20px" /></div>
                    </div>
                    <div className="input-box6">
                      <PhoneInput
                        country={'ae'}
                        required
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(value) => handleInputChange({ target: { name: 'phoneNumber', value } })}
                        inputProps={{ id: 'input-field', required: true }}
                        containerClass="phone-input-custom"
                      />
                      <div><img src="assets/images/phone.png" alt="" className="phoneicon" width="20px" /></div>
                    </div>
                    {formErrors.phoneNumber && <small className="error" style={{ color: 'red' }}>{formErrors.phoneNumber}</small>}
                    <div className="input-box6">
                      <input
                        type="text"
                        required
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Leave Us a Few words</span>
                      <div> <i className="fa-solid fa-bars lines"></i></div>
                    </div>
                    <div className="button-sec6">
                      <button className="btn btn-primary submit6" >
                        Send Enquiry
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>

          
          </div>
        </div>
      </div>
    </>
  )
}

export default CloudBanner