import React from 'react'
import ZohoSolution from './ZohoSolution/ZohoSolution'
import ZohoWorks from './ZohoWorks/ZohoWorks'
import ZohoProces from './ZohoProcess/ZohoProces'
import WhyZoho from './WhyZoho/WhyZoho'

function ZohoSolutionpage() {
  return (
    <>
    <ZohoSolution/>
    <ZohoWorks/>
    <ZohoProces/>
    <WhyZoho/>
    </>
  )
}

export default ZohoSolutionpage