import React from 'react'
import '../AboutusStory/AboutusStory.css'

function AboutusTarget() {
  return (
    <>
     <div className="container aboutSection">
    <div className="about_theme">

        <div className="about_our">
          <div className="image" data-aos="flip-up">
              <div className="decribe_img"><img src="assets/images/approach.jpg" alt=""/></div>
              </div>
              <div className="col_about">
                <h5>Our Approach</h5>
                <p>We believe that technology should be an enabler, not a barrier. Our approach is client-centric, focusing on understanding 
                  your specific needs and delivering solutions that align with your business goals. We pride ourselves on our ability to blend 
                  technical prowess with innovative thinking, ensuring that our solutions are not only effective but also future-proof.</p>
              </div>
        </div>

        <div className="about_our">
          <div className="image" data-aos="flip-up">
          <div className="decribe_img"><img src="assets/images/team.jpg" alt=""/></div></div>
          <div className="col_about">
            <h5>Our Team</h5>
            <p>Our greatest asset is our team. Comprised of seasoned IT professionals, creative thinkers, and industry experts, we work 
              collaboratively to deliver exceptional results. Our team's diverse backgrounds and skills enable us to tackle projects of 
              any scale and complexity with confidence and precision.</p>
          </div>
        </div>

        <div className="about_our">
          <div className="image" data-aos="flip-up">
          <div className="decribe_img"><img src="assets/images/commitments.jpg" alt=""/></div></div>
          <div className="col_about">
            <h5>Our Commitment</h5>
            <p>At Sahara Technologies, we are committed to excellence in every aspect of our work. We adhere to the highest standards of 
              quality, integrity, and customer service. Our goal is to build long-lasting relationships with our clients, based on trust, 
              transparency, and mutual success.</p>
          </div>
        </div>

    </div>
  </div>
    </>
  )
}

export default AboutusTarget