import React, { useEffect, useState } from 'react'
import './contactBanner.css'
import 'animate.css';
import 'animate.css/animate.min.css';


function ContactBanner() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
  <>
    <div className="container-fluid p-0 section3">
    <div className="row ">
      <div className="banner3">
        <div className="col-md-9">
          <div className="content3" >
            <h1 className={` ${animate ? 'animate__animated animate__slideInRight' : ''}`}><span>Connect with Us</span><br/>We're Just a Message Away!"</h1>
        </div>
        </div>
        <div className="bar3"></div>
       
    </div>
  </div>
</div>
  </>
  )
}

export default ContactBanner