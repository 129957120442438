import React, { useEffect, useState } from 'react'
import './service.css'
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation , Scrollbar, A11y, Autoplay, Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide,  } from 'swiper/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import 'animate.css/animate.min.css';



function Service() {
  const [animate, setAnimate] = useState(false);
  const [sqaure, setSqaure] = useState(false);



  //squar image rotation 
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSqaure(true);

      // Remove the animation className after it completes (assuming 1 second for the animation)
      setTimeout(() => {
        setSqaure(false);
      }, 1000); // Adjust this time according to the duration of your animation
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(prevAnimate => !prevAnimate);
    }, 3000); 
    
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 5000, 
      loop: true, // Whether animation should happen only once
     
    });
  }, []);
  return (
    <>

    <div className="container-fluid p-0 ">
    <Swiper 
    modules={[Navigation, Pagination, Autoplay]}
    navigation
    loop={true}
      autoplay={{ delay: 5000 }}
   >
     <SwiperSlide>
  <div className="service">
    <div className="container">
      <div className="row">
        <div className="col-md-12 service-sec">
          <div   className={`mission ${animate ? 'animate__animated animate__backInDown' : ''}`}>
            <h6>MISSION</h6>
          <h1 >01</h1></div>
          <div className="service-content">
          <h3>To innovate and deliver cutting-edge technology solutions that drive progress and improve lives globally.</h3>
         
        </div>
        </div>
      </div>
    </div>
  </div>
  </SwiperSlide>
     <SwiperSlide>
  <div className="service">
    <div className="container">
      <div className="row">
        <div className="col-md-12 service-sec">
          <div  className={`mission ${animate ? 'animate__animated animate__backInDown' : ''}`}>
            <h6>VISION</h6>
          <h1 >02</h1></div>
          <div className="service-content">
          <h3>To be the catalyst for a digitally driver world, where possibilities are endless and growth is limitless.</h3>
          
        </div>
        </div>
      </div>
    </div>
  </div>
  </SwiperSlide>
     <SwiperSlide>
  <div className="service">
    <div className="container">
      <div className="row">
        <div className="col-md-12 service-sec">
          <div  className={`mission ${animate ? 'animate__animated animate__backInDown' : ''}`}>
            <h6>VALUES</h6>
          <h1 >03</h1></div>
          <div className="service-content">
          <h3>Innovation+Excellence+Integrity+ Collaboration+Customer-centricity+ Agility+Inclusivity: and more</h3>
          
        </div>
        </div>
      </div>
    </div>
  </div>
  </SwiperSlide>
  </Swiper>
  <div className="your_success">
  <h5>Your Business, Our Expertise: Seamless Success Ahead</h5>
          <p>In today’s fast-paced digital world, managing change can be daunting. At Sahara 
Technologies, we simplify the complex. With our extensive expertise in digital 
transformation, we design innovative solutions tailored to your specific business needs. 
Whether it's optimizing operations or enhancing customer experiences, our commitment is to 
drive your success. As your trusted partner, we'll leverage cutting-edge technology and deep 
industry knowledge to guide your business towards a seamless and prosperous future. 
Together, we'll unlock new opportunities and achieve remarkable outcomes. </p>
</div>

</div>


<div className="sqaure-img"><img src="assets/images/1.png" alt=""  className={` ${sqaure ? 'animate__animated animate__rotateIn' : ''}`}/></div>




    </>
  )
}

export default Service