import React, { useEffect, useState } from 'react'
import './CloudAbout.css'

function CloudAbout() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
<>
<div className="security6">
  <div className="row se6c">
    <div className="col-9 security-operation6">
      <img src="assets/images/security-img.png"  className={` ${animate ? 'animate__animated animate__flipInX' : ''}`} alt=""/>
        <h2>Comprehensive Security Operations and Response Services</h2>
        <p id="para">Introducing innovative, flexible, and modular security services 
          designed to modernize your security operations</p>
    </div>
  </div>
</div>
</>
  )
}

export default CloudAbout