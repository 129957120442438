import React, { useEffect } from 'react'
import '../ZohoSolution.css' 
import AOS from 'aos';
import 'aos/dist/aos.css';


function ZohoProces() {
  useEffect(() => {
   
    AOS.init({});
  }, []);
  return (
    <>
    <div className="process">

<div className="workingProcess_content">

  <div className="column4-1">
    <div className="IMG"><img src="assets/images/share_project.png" data-aos="zoom-in" alt=""/></div>
    <div><p>Share project <br/>requirements</p></div>
  </div>

  <div className="column4-1">
    <div className="IMG"><img src="assets/images/hire.png" data-aos="zoom-in" alt=""/></div>
    <div><p>Hire our <br/>developer</p></div>
  </div>

  <div className="column4-1">
    <div className="IMG"><img src="assets/images/get_project.png" data-aos="zoom-in" alt=""/></div>
    <div><p>Get the project <br/>completed</p></div>
  </div>

  <div className="column4-1">
    <div className="IMG"><img src="assets/images/QA.png" data-aos="zoom-in" alt=""/></div>
    <div><p>QA <br/>& Testing</p></div>
  </div>

</div>
</div>
    </>
  )
}

export default ZohoProces