import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import OdooBanner from './OdooBanner/OdooBanner'
import OdooAbout from './OdooAbout/OdooAbout'
import Implementation from './Imlementation/Implementation'
import OdooDeployment from './OdooDeployment/OdooDeployment'
import DeploymentSection from './DeploymentSection/DeploymentSection'
import ERPImplementation from './ERP_implementation/ERPImplementation'
import KeyComponents from './Keycomponents/KeyComponents'
import CountDown from '../CountDown/CountDown'

function OdooPage() {
  return (
   <>
   <div className="wrapper">
     <Navbar/>
     <OdooBanner/>
     <OdooAbout/> 
     <Implementation/>
     <OdooDeployment/>
     <DeploymentSection/>
     <ERPImplementation/>
     <KeyComponents/>
    <Footer/>
   </div>
   </>
  )
}

export default OdooPage