import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './OdooBanner.css';
import 'animate.css';
import 'animate.css/animate.min.css';
import useForm from '../../Foms/Form'
import Reloader from '../../PReloader/Reloader';

import CountDown from '../../CountDown/CountDown';

function OdooBanner() {
  const [animate, setAnimate] = useState(false)
  const { formData, formErrors, loading, handleInputChange, handleSubmit } = useForm();

  // useEffect(() => {
  //   const currentTime = new Date().getHours();
  //   if (currentTime >= 8 && currentTime < 14) {
  //     setColClass('col-md-4');
  //   } else {
  //     setColClass('col-md-6');
  //   }
  // }, []);

  useEffect(() => {
    setAnimate(true);
  }, [])
  return (
    <>
      <div className="container-fluid p-0 sectionOdo">
        <div className="row">
          <div className="bannerOdo">
            <div className="col-md-8 hometimer">
              <div className="main-contentOdo">
                <div className="side-barOdo">
                  <div className="lineOdo1"></div>
                  <div className="circleOdo">
                    <div className="black-circleOdo"></div>
                  </div>
                  <div className="lineOdo"></div>
                </div>
                <div className="contentOdo">
                  <div className={` odoo-official ${animate ? 'animate__animated animate__slideInRight' : ''}`}>
                    <img src="assets/images/odoo_official.png" alt="Odoo Official" />
                  </div>
                  <h1 className={` ${animate ? 'animate__animated animate__slideInRight' : ''}`}>
                    Streamline your business with ease - <br />
                    <span style={{ color: '#a610ed' }}>
                      Odoo ERP solutions Tailored for you
                    </span>
                  </h1>
                  <p>
                    Simplify operations, boost productivity with our customizable Odoo ERP
                  </p>
                  <h5>
                    Secure Your 20% Discount on Odoo <br />
                    Implementation Today!
                  </h5>
                  <ul className="listOdo">
                    <li>
                      <img src="assets/images/tick2.png" alt="Tick" />
                      Integrate departments seamlessly,<br />
                      enhance collaboration.
                    </li>
                    <li>
                      <img src="assets/images/tick2.png" alt="Tick" />
                      Scalable solutions grow as your<br />
                      business flourishes
                    </li>
                    <li>
                      <img src="assets/images/tick2.png" alt="Tick" />
                      Real-time data analytics for informed<br />
                      decision making
                    </li>
                    <li>
                      <img src="assets/images/tick2.png" alt="Tick" />
                      Effortless adoption with user-friendly,<br />
                      intuitive interfaces
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bar5"></div>

              <CountDown/>
            </div>
          
           

              <div  >
              <div className="Odoform">
              <div className="heroFormOdo">
                {loading ? (
                  <Reloader />
                ) : (
                  <form onSubmit={handleSubmit} className='formOdo'>
                    <h6>Transform Your Business Now</h6>
                    <div className="input-boxOdo">
                      <input
                        type="text"
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Full Name</span>
                      <div>
                        <img src="assets/images/user.png" alt="User Icon" width="20px" />
                      </div>
                    </div>
                    <div className="input-boxOdo">
                      <input
                        type="text"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Email</span>
                      <div>
                        <img src="assets/images/email.png" alt="Email Icon" width="20px" />
                      </div>
                    </div>
                    <div className="input-boxOdo">

                      <PhoneInput
                        country={'ae'}
                        required
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(value) => handleInputChange({ target: { name: 'phoneNumber', value } })}
                        inputProps={{ id: 'input-field', required: true }}
                        containerClass="phone-input-custom"
                      />
                      <div>
                        <img src="assets/images/phone.png" alt="Phone Icon" className='phoneicon' width="20px" />
                      </div>
                    </div>
                    {formErrors.phoneNumber && <small className="error" style={{ color: 'red' }}>{formErrors.phoneNumber}</small>}
                    <div className="input-boxOdo">
                      <input
                        type="text"
                        required
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Leave Us a Few words</span>
                      <div>
                      <i className="fa-solid fa-bars lines"></i>
                      </div>
                    </div>
                    <div className="button-secOdo">
                      <button className="btn btn-primary submitOdo" >
                        Send Enquiry
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
              </div>
         
           

           
   
          </div>
          <div className="services_listOdo">
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Implementation
            </li>
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Customization
            </li>
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Training
            </li>
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Support
            </li>
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Integration
            </li>
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Consultancy
            </li>
            <li>
              <div>
                <img src="assets/images/circle.png" alt="Circle" />
              </div>
              Migration
            </li>
          </div>
        </div>
      </div>
    </>
  );
}

export default OdooBanner;
