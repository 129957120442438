import React from 'react'
import Blogbanner from './BlogBanner/Blogbanner'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import BlogAbout from './BlogAbout/BlogAbout'

function BlogPage() {
  return (
 <>
   <div className="wrapper">
    <Navbar/>
   <Blogbanner/>
   <BlogAbout/>
   <Footer/>
   </div>

 </>
  )
}

export default BlogPage