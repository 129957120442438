import React, { useEffect } from 'react'
import './ComputingService.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function ComputingService() {
  useEffect(() => {
    
    AOS.init({});
  }, []);
  return (
  <>
  <div className=" cloud-servicesContainer">
  <div className="row1_services">
        <div className="cloud-services">
            <div className="services8">
              <div className="service-content8">
                <h5>Cloud Consulting</h5>
                <p>Tap into our decades of experience managing some of the world's most complex IT environments to craft your ideal 
                  cloud strategy, streamline your IT architecture, and achieve your desired business outcomes.</p>
              </div>
              <div className="image8" data-aos="zoom-in-left"><div className="left-bar"></div><img src="assets/images/img1.jpg" alt=""/></div>
            </div>

            <div className="services8 align8">
              <div className="image8" data-aos="zoom-in-right"><img src="assets/images/img2.jpg" alt=""/><div className="left-bar"></div></div>
              <div className="service-content8">
                <h5>Private Cloud Services</h5>
                <p>Discover the ideal balance of cloud efficiency and private infrastructure performance, compliance, and security, 
                  enabling you to modernize and manage your enterprise effectively. Our unified approach spans across the entire cloud 
                  landscape, integrating dedicated on-prem resources with seamless public cloud workload integration</p>
              </div>
            </div>

            <div className="services8">
              <div className="service-content8">
                <h5>Public Cloud Services</h5>
                <p>Our proven, standardized, and repeatable services are meticulously crafted to empower you to seamlessly design and 
                  deploy your applications and workloads on the ideal public cloud platform for your enterprise. Sahara Public Cloud 
                  services prioritize security and ease of management, offering flexible deployment options tailored to your specific 
                  needs. From initial design to ongoing management, our services provide comprehensive, integrated, and full-lifecycle 
                  support for cloud environments, all built on industry best practices and aligned with unique cloud adoption and management 
                  frameworks.</p>
              </div>
              <div className="image8" data-aos="zoom-in-left"><div className="left-bar"></div><img src="assets/images/img3.jpg" alt=""/></div>
            </div>

            <div className="services8 align8">
              <div className="image8" data-aos="zoom-in-right"><img src="assets/images/img4.jpg" alt=""/><div className="left-bar"></div></div>
              <div className="service-content8">
                <h5>Cloud Migration Services</h5>
                <p>Discover the ideal balance of cloud efficiency and private infrastructure performance, compliance, and security, 
                  enabling you to modernize and manage your enterprise effectively. Our unified approach spans across the entire cloud 
                  landscape, integrating dedicated on-prem resources with seamless public cloud workload integration</p>
              </div>
            </div>

            
            <div className="services8">
              <div className="service-content8">
                <h5>Managed Cloud Services</h5>
                <p>With unmatched capabilities in public, private, and hybrid cloud solutions, Kyndryl empowers enterprises to optimize 
                  and modernize their infrastructure and applications, fully leveraging the cloud's potential. Our comprehensive suite 
                  includes managed services that cover the entire IT stack, ensuring you maximize the potential of your IT infrastructure 
                  with Kyndryl Managed Services.</p>
              </div>
              <div className="image8" data-aos="zoom-in-left"><div className="left-bar"></div><img src="assets/images/img5.jpg" alt=""/></div>

            </div>
</div>
        </div>
  

        
          <div className="contact-btn8">
          <a href="/contact "> <button type="button" className="btn btn-primary btn-lg contact8">CONTACT US NOW</button></a>
          </div>
 
        </div>
  </>
  )
}

export default ComputingService