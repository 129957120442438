import React, { useEffect } from 'react'
import './Customer.css'
import AOS from 'aos';
import 'aos/dist/aos.css';



function Customer() {
  useEffect(() => {
    AOS.init({
      duration: 3000, // Animation duration
      loop: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <>

  <div className="customers ">
    <div className="customers-container">
        <div className="cust">
          <h6>CUSTOMERS</h6>
          <h1>Your <br/>
            Success, <br/>
            Our <br/>
            Commitment</h1>
        </div>
        <div className="cust1">
          <img src="assets/images/smile.png" alt="" width="300px" id="smile"/>
          <p>Our clients are at the heart of everything we do, and we're proud to support their innovative endeavors with our cutting-edge technology solutions</p>
        </div>
    </div>

  </div>

  {/* id="smile" */}

<div className="clients" >
     <div className='client-logo'>
<li><img src="assets/images/odoo_slider.png" alt="" width="100%"/></li>
<li><img src="assets/images/google.png" alt="" width="100%"/></li>
<li><img src="assets/images/microsoft.png" alt="" width="100%"/></li>
<li><img src="assets/images/zoho_slide.png" alt="" width="100%"/></li>
<li><img src="assets/images/aws.png" alt="" width="100%"/></li>
<li><img src="assets/images/redhat.png" alt="" width="100%"/></li>
</div>
</div>
    </>
  )
}

export default Customer