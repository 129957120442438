import React from 'react'
import './Reloader.css'

function Reloader() {
  return (
    <>


<div id="loading"><img src="assets/images/preloader.png" alt="" id="preloader"/><span id="sahara">S</span></div>
    </>
  )
}

export default Reloader