import React, { useEffect } from 'react'
import '../Future.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function FuturBottom() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
   <>
    <div className="container px-4 text-center services-container">
<div className="row gx-5 best-ser">
  <div className="col col6" data-aos="zoom-in-right">
   <div className="p-3 services6">
    <div><img src="assets/images/1.pass&access.png" alt="" /></div>
      <div className="services-content" >
        <h6>Password & Access Management</h6>
        <p>We help organizations establish effective password and access management, ensuring you know who has access and 
          why—eliminating password reuse concerns."</p>
      </div>
   </div>
  </div>
  <div className="col col6" data-aos="zoom-in-left">
    <div className="p-3 services6">
      <div><img src="assets/images/2.attack-mitigation.png" alt=""/></div>
      <div className="services-content">
        <h6>Attack Mitigation</h6>
        <p>Our services help mitigate cyber threats like account compromise, unauthorized access, ransomware, network intrusions,
           malware, sabotage, and policy violations</p>
      </div>
    </div>
  </div>
</div>

<div className="row gx-5 best-ser">
  <div className="col col6" data-aos="zoom-in-right">
   <div className="p-3 services6">
      <div><img src="assets/images/3.sec-awarensse.png" alt=""/></div>
      <div className="services-content">
        <h6>Security Awareness Training</h6>
        <p>We provide impactful security awareness training that resonates emotionally and logically with everyone. Your Virtual CISO will enhance 
          security awareness across the organization.</p>
      </div>
   </div>
  </div>

  <div className="col col6" data-aos="zoom-in-left">
    <div className="p-3 services6">
      <div><img src="assets/images/4.cloud-sec.png" alt=""/></div>
      <div className="services-content">
        <h6>Cloud Security Architecture</h6>
        <p>Microsoft 365 has more than 280 security settings. Amazon Web Services and Azure also offer hundreds of security configuration options 
           -your virtual CISO takes care of ALL of them!</p>
      </div>
    </div>
  </div>

</div>

<div className="row gx-5 best-ser">
 


</div>

<div className="row gx-5 best-ser">
  <div className="col col6" data-aos="zoom-in-right">
   <div className="p-3 services6">
    <div><img src="assets/images/6.vulanerability.png" alt=""/></div>
      <div className="services-content">
        <h6>Vulnerability management</h6>
        <p>Do you know how many vulnerable machines or apps are on your network? We help set up and manage a vulnerability management 
          program to reduce them.</p>
      </div>
   </div>
  </div>

    
  <div className="col col6" data-aos="zoom-in-left">
    <div className="p-3 services6">
      <div><img src="assets/images/5.securingIT.png" alt=""/></div>
      <div className="services-content">
        <h6>Securing IT Infrastructure</h6>
        <p>We transform your IT security with server and network hardening, desktop hardening, network and web security, data protection, backups, 
          and more</p>
      </div>
    </div>
  </div>
  
 

</div>

<div className="row gx-5 best-ser">
  <div className="col " data-aos="zoom-in-right">
   <div className="p-3 services6">
   <div><img src="assets/images/7.email& communications.png" alt=""/></div> 
      <div className="services-content">
        <h6>Email & Communications Security</h6>
        <p>Unauthorized access to a corporate account can compromise any internal system. We implement secure authentication to ensure the 
          integrity and confidentiality of your communications.</p>
      </div>
   </div>
  </div>
   
  <div className="col col6" data-aos="zoom-in-left">
    <div className="p-3 services6">
      <div><img src="assets/images/8.security-monitoring.png" alt=""/></div>
      <div className="services-content">
        <h6>Security Monitoring</h6>
        <p>We enhance your IT security with server, network, and desktop hardening, web service security, data protection, backups, 
          and more.</p>
      </div>
    </div>
  </div>

</div>


</div>


<div className="contact-btn6">
<a href="/contact"> <button type="button" className="btn btn-primary btn-lg contact6">CONTACT US NOW</button></a>
</div>

   </>
  )
}

export default FuturBottom