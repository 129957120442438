import React, { useState } from 'react'
import './Contact.css'
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Reloader from '../PReloader/Reloader';


function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    city: '',
    email: '',
    phoneNumber: '',
    details: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    companyName: '',
    city: '',
    email: '',
    phoneNumber: '',
    details: ''
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation
    let errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    }
    
    if (!formData.companyName.trim()) {
      errors.companyName = 'Company name is required';
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = 'City is required';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
      isValid = false;
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
      isValid = false;
    }

    if (!formData.details.trim()) {
      errors.details = 'Details are required';
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      setLoading(true);
      try {
        const result = await emailjs.send(
          'service_ir3eljh',
          'template_4mhr8ro', 
          formData, 
          '4Ne5WItibjK46Jkzl' 
        );
        setFormData({
          name: '',
           companyName: '',
          city: '',      
          email: '',
          phoneNumber: '',
           details: ''
      
        });
        setLoading(false);
        Swal.fire({
          title: 'Success!',
          text: 'Form submitted successfully!',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
    
      } catch (error) {
        setLoading(false);
        Swal.fire({
          title: 'Error!',
          text: 'Error submitting form. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
    
      }
    }
  };
  return (
    <>
       <div className=" px-4 text-center contact">
       <div className="row gx-5">

      <div className="col location4">
       <div className="p-3 locate-to-contact ">
        <h5>Combining Service with Convenience for You  </h5>
        <button type="button" className="btn btn-primary" id=''>CORPORATER OFFICE</button>

        <div className="address4">
            <h6 >Sahara Technologies LLC</h6>
            <div className="locate-address4">
            <ul className="list-group">
              <li className="list-group-item4"><i className="fa-solid fa-location-dot icon4"></i><div style={{marginTop:"15px"}}><p> Industrial Area 11, Sharjah Industrial Center Road, <br/>
              Al Arabi Building, <br />PO Box 47373-Sharjah-UAE</p></div></li>
              <li className="list-group-item4"><i className="fa-solid fa-phone icon4"></i>+971 50 485 2446</li>
              <li className="list-group-item4"><i className="fa-solid fa-envelope icon4"></i>saharatechnologiesuae@gmail.com</li>
            </ul>

            <div><img src="assets/images/location.png" alt="" width="60%"/></div>
          </div>

          

          {/* <div className="line4"></div>
          <h6>Sahara Technologies Pvt. Ltd</h6>
          <button type="button" className="btn btn-primary butt">INDIA</button>
          <div className="locate-address4">
            <ul className="list-group">
              <li className="list-group-item4"><i className="fa-solid fa-location-dot icon4"></i><div style={{marginTop:"15px"}}><p> Industrial Area 11, Sharjah Industrial Center Road, <br/>
                Al Arabi Building, <br />PO Box 47373-Sharjah-UAE</p></div></li>
              <li className="list-group-item4"><i className="fa-solid fa-phone icon4"></i>+971 0504852446</li>
              <li className="list-group-item4"><i className="fa-solid fa-envelope icon4"></i>uae@febno.com</li>
            </ul>

            <div><img src="assets/images/location.png" alt="" width="60%"/></div>
          </div> */}

        </div>

       </div>
      </div>

      <div className="col enquiry-form">
      <div className="p-3 enquiry">
        <h5>Enquiry Form</h5>
        <p>For a quick and easy way to get in touch, fill out our online contact form. 
          Provide us with some details about your query, and our team will respond promptly.</p>
          {loading ? (
      <Reloader />
    ) : (
        <form id='form' onSubmit={handleSubmit}>
          <div className="input-box">
            <input type="text" required name="name" value={formData.name} onChange={handleInputChange} />
            <span>your name</span>
            {formErrors.name && <span className="error" style={{ color: 'red' }}>{formErrors.name}</span>}
          </div>

          <div className="input-box">
            <input type="text" required name="companyName" value={formData.companyName} onChange={handleInputChange} />
            <span>company name</span>
            {formErrors.companyName && <span className="error" style={{ color: 'red', }}>{formErrors.companyName}</span>}
          </div>

          <div className="input-box">
            <input type="text" required name="city" value={formData.city} onChange={handleInputChange} />
            <span>city</span>
            {formErrors.city && <span className="error" style={{ color: 'red' }}>{formErrors.city}</span>}
          </div>

          <div className="input-box">
            <input type="text" required name="email" value={formData.email} onChange={handleInputChange} />
            <span>email address</span>
            {formErrors.email && <span className="error" style={{ color: 'red' }}>{formErrors.email}</span>}
          </div>

          <div className="input-box">
            <input type="number" required name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
            <span>phone number</span>
            {formErrors.phoneNumber && <span className="error" style={{ color: 'red' }}>{formErrors.phoneNumber}</span>}
          </div>

          <div className="input-box">
            <textarea name="details" value={formData.details} onChange={handleInputChange}></textarea>
            <span>details</span>
            {formErrors.details && <span className="error" style={{ color: 'red' }}>{formErrors.details}</span>}
          </div>

          <div className="button-sec">
            <button className="btn btn-primary submit" type="submit">SUBMIT</button>
          </div>
        </form>
         )}
      </div>
    </div>

    </div>
  </div>
    </>
  )
}

export default Contact