import React, { useEffect, useState } from 'react'
import './Zohoservice.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import 'animate.css/animate.min.css';

function ZohoService() {
  const [animate , setAnimate] = useState(false)
  useEffect(() => {
    setAnimate(true);
    AOS.init({});
  }, []);
  return (
   <>
   <div className="servicesSec">
  <div className="row_section">
    <div className="col-service">
        <div><img src="assets/images/1.healthcare.png" data-aos="flip-right" alt=""/></div>
        <div className="col3-1">
          <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Healthcare</h4>
          <p>Zoho for healthcare helps oversee your healthcare facility administration, patient management, faster billing, and more.
          </p>
        </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/2.education.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Education</h4>
        <p>Handle all your administrative workflows and other educational management seamlessly with Zoho for education Reach out to us today!
        </p>
      </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/3.bank&financial.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Bank & Financials</h4>
        <p>Lead Management to Managing Books of Accounts, Zoho for banking & financials is a must for any financial services.
        </p>
      </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/4.real_estate.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Real Estate</h4>
        <p>Timely lead management, Deals, contracts, signing of agreements, and more, a perfect solution, Zoho for real estate.
        </p>
      </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/5.manufacturing.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Manufacturing</h4>
        <p>Automate your production process, reduce workforce energy and costs, and increase efficiency with Zoho manufacturing.
        </p>
      </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/6.solar.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Solar Industry</h4>
        <p>A Field Service solution using Zoho that helped streamline their office and fieldwork.
        </p>
      </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/7.travel_agency.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>Travel Agency</h4>
        <p>Manage your sales, customer & supplier records with Zoho travel agency CRM and save workforce energy, time, and expenses.
        </p>
      </div>
    </div>

    <div className="col-service">
      <div><img src="assets/images/8.e-commerce.png" data-aos="flip-right" alt=""/></div>
      <div className="col3-1">
        <h4 className={` ${animate ? 'animate__animated animate__flipInX' : ''}`}>eCommerce</h4>
        <p>Track, engage, and run campaigns and other processes with Zoho integrations, including Zoho CRM for eCommerce.
        </p>
      </div>
    </div>

  </div>
</div>
   </>
  )
}

export default ZohoService