import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './ZohoBanner.css';
import 'animate.css';
import 'animate.css/animate.min.css';
import useForm from '../../Foms/Form'
import Reloader from '../../PReloader/Reloader';


function ZohoBanner() {
  const [animate, setAnimate] = useState(false)
  const { formData, formErrors, loading, handleInputChange, handleSubmit } = useForm();

  useEffect(() => {
    setAnimate(true);
  }, [])
  return (
    <>
      <div className="container-fluid p-0 sectionzoho">
        <div className="row ">
          <div className="bannerzoho">
            <div className="col-md-8 ">
              <div className="main-contentzoho">
                <div className="side-barzoho">
                  <div className="linezoho"></div>
                  <div className="circlezoho">
                    <div className="black-circlezoho"></div>
                  </div>
                  <div className="line1zoho"></div>
                </div>
                <div className="contentzoho" >

                  <div className={` zoho ${animate ? 'animate__animated animate__slideInRight' : ''}`}><img src="assets/images/zoho_logo.png" alt="" /></div>
                  <h1 className={` ${animate ? 'animate__animated animate__slideInRight' : ''}`} ><span style={{ color: '#a610ed' }}>Zoho</span>  Partner</h1>
                  <p>Providing Proficient Zoho Setup
                    and Implementation</p>
                  <h5>As Zoho  partners, we have
                    the best Zoho Development team and
                    worked on Implementation,
                    Development, Integrations projects
                    of various complexities
                    for different industrial sectors.</h5>
                </div>
              </div>

              <div className="bar5"></div>
            </div>

            <div className="">
              <div className="heroFormzoho">
                {loading ? (
                  <Reloader />
                ) : (
                  <form onSubmit={handleSubmit} className='zohofrom'>
                    <h6>BOOK NO-OBLIGATION <br /> CONSULTATION NOW !</h6>
                    <div className="input-boxzoho">
                      <input
                        type="text"
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Full Name</span>
                      <div><img src="assets/images/user.png" alt="" width="20px" /></div>
                    </div>

                    <div className="input-boxzoho">
                      <input
                        type="text"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>email</span>
                      <div><img src="assets/images/email.png" alt="" width="20px" /></div>
                    </div>

                    <div className="input-boxzoho">
                      <PhoneInput
                        country={'ae'}
                        required
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(value) => handleInputChange({ target: { name: 'phoneNumber', value } })}
                        inputProps={{ id: 'input-field', required: true }}
                        containerClass="phone-input-custom"
                      />

                      <div><img src="assets/images/phone.png" className='phoneicon' alt="" width="20px" /></div>
                    </div>
                    {formErrors.phoneNumber && <small className="error" style={{ color: 'red' }}>{formErrors.phoneNumber}</small>}

                    <div className="input-boxzoho">
                      <input
                        type="text"
                        required
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Leave Us a Few words</span>
                      <div> <i className="fa-solid fa-bars lines"></i></div>
                    </div>

                    <div className="button-seczoho">
                      <button className="btn btn-primary submitzoho">sent enquiry</button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          
          </div>

          <div className="services_list1zoho">
            <div className="row_itemzoho">
              <li><div><img src="assets/images/circle.png" alt="" /></div>Consulting </li>
              <li><div><img src="assets/images/circle.png" alt="" /></div> Implementation </li>
              <li><div><img src="assets/images/circle.png" alt="" /></div> Development </li>
              <li><div><img src="assets/images/circle.png" alt="" /></div>Training</li>
              <li><div><img src="assets/images/circle.png" alt="" /></div>Integration</li>
              <li><div><img src="assets/images/circle.png" alt="" /></div>Support</li>
              <li><div><img src="assets/images/circle.png" alt="" /></div>maintenance </li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ZohoBanner;
