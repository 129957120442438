import React from 'react'
import Banner from '../Banner/Banner'
import './Home.css'
import About from '../About/About'
import BannerVideo from '../BannerVideo/BannerVideo'
import Service from '../Service/Service'
import Customer from '../Customer/Customer'
import Rooms from '../NewsRooms/Rooms'
import Footer from '../Footer/Footer'
function Home() {
  return (
  <>
   <div className="container-fluid  homepage">
   <Banner/>
   <About/>
   {/* <BannerVideo/> */}
   <Service/>
   <Customer/>
   <Rooms/>
   <Footer />
   </div>

  </>
  )
}

export default Home