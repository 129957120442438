import React, { useEffect, useState } from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import 'animate.css/animate.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation , Scrollbar, A11y, Autoplay, Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide,  } from 'swiper/react';


function About() {
  const [currentSlide, setCurrentSlide] = useState(1); 
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      loop: true,
    });
    setAnimate(true);
   
    AOS.init();

    // Function to move to the next slide
    const moveNextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide % 3) + 1);
    };

    // Set interval to move to the next slide every 3 seconds
    const interval = setInterval(moveNextSlide, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="second ">
        <div className="achieve-more">
          <img src="assets/images/runner.png" data-aos="zoom-in-right"
                alt="Runner" id="runner" />
           <div className="col2-about">
          <div className="main-head">
            <div   className={`main ${animate ? 'animate__animated animate__zoomInDown' : ''}`}>
              <h6 style={{padding: '0px 5px'}}>TECHNOLOGIES</h6>
              <h1>ACHIEVE <br /> MORE <br />WITH <br /><span className="highlight">TECH</span></h1>
            </div>
          </div>
          
      
          <div className="swiper cont_slider">
            <div className="swiper-wrapper">
            <Swiper 
    modules={[Navigation, Pagination, Autoplay]}
    navigation
    loop={true}
      autoplay={{ delay: 5000 }}
   >
       <SwiperSlide>
          <div className="text swiper-slide">
          <h5>Outcome-driven 
            solutions that 
            make your business 
            goals a reality <br/>
          <img src="assets/images/odoo-logo.png" alt="" width="65%"/></h5>
        </div>
        </SwiperSlide>
        <SwiperSlide>
       <div className="text swiper-slide">
          <h5>Empowers businesses to 
            streamline processes, 
            enhance productivity, 
            and drive success <br/>
            <img src="assets/images/zoho.png" alt="" width="70%"/></h5>
        </div>
</SwiperSlide>
<SwiperSlide>
        <div className="text swiper-slide">
          <h5>Elevate your enterprise with 
our comprehensive range 
of cloud solutions <br/>
            <img src="assets/images/cloud.png" alt="" width="70%"/></h5>
        </div>
        </SwiperSlide>
<SwiperSlide>
        <div className="text swiper-slide">
          <h5>Comprehensive Security 
            Operations and Response 
            Services <br/>
            <img src="assets/images/security.png" alt="" width="70%"/></h5>
        </div>
        </SwiperSlide>


</Swiper>
      </div>
      </div>
      
      
        </div>
          </div>
        
       
      </div>
      <div className="animation-div">
          <div className="arrow-img" ></div> 
        </div>
    </>
  );
}

export default About;
