import React, { useEffect, useState } from 'react'
import './SolutionContents.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function SolutionContents() {
  useEffect(() => {
    
    AOS.init({});
  }, []);
  return (
    <>
    <div className="container solutions7">
  <div className="row">
    <div className="col solution-container" >
      <h5>Empowering Your Digital Evolution</h5>
      <p>Empowering businesses through digital transformation involves leveraging technology to streamline operations, enhance efficiency, 
        and drive innovation. By embracing digital solutions, businesses can adapt to market changes more swiftly, improve customer 
        experiences, and achieve sustainable growth in today's dynamic landscape.</p>
    </div>
  </div>

  <div className="row gy-5 ">
    <div className="col-6 digital-solutions" data-aos="zoom-in">
      <div className="p-3 ">
        <div className="solu-content">
          <div className="image7"><img src="assets/images/cyber-sec.jpg" alt=""/></div>
          <div className="main-solutions"><h6>Cyber Security</h6>
          <p>Explore our cybersecurity solutions designed to safeguard your digital assets and protect against evolving threats. From proactive 
            threat detection to robust data encryption and compliance management,</p></div>
        </div>
      </div>
      <div className="arrow7"><a href="/cloud"><button className="arrow-btn7"><img src="assets/images/arrow_s.png" alt=""/></button> </a></div>
    </div>

    <div className="col-6 digital-solutions" data-aos="zoom-in">
      <div className="p-3 ">
        <div className="solu-content">
          <div className="image7"><img src="assets/images/cloud-solu.jpg" alt=""/></div>
          <div className="main-solutions "><h6>Cloud Solutions</h6>
          <p>Discover our comprehensive cloud solutions designed to accelerate your business transformation. From flexible and scalable infrastructure services 
            to advanced data analytics and seamless integration across platforms</p></div>
        </div>
      </div>
      <div className="arrow7"><a href="/cloudcomputing"><button className="arrow-btn7"><img src="assets/images/arrow_s.png" alt=""/></button></a></div>
    </div>
    
   
    <div className="col-6 digital-solutions" data-aos="zoom-in">
      <div className="p-3 ">
        <div className="solu-content">
          <div className="image7"><img src="assets/images/odoo-solu.jpg" alt=""/></div>
          <div className="main-solutions"><h6>ODOO Solutions</h6>
          <p>Explore our tailored Odoo solutions designed to streamline and optimize your business operations. From integrated ERP and CRM systems to 
            customized modules and scalable solutions</p></div>
        </div>
    </div>
    <div className="arrow7"><a href="/odoo"><button className="arrow-btn7"><img src="assets/images/arrow_s.png" alt=""/></button></a></div>
  </div>
   
  <div className="col-6 digital-solutions" data-aos="zoom-in">
    <div className="p-3">
      <div className="solu-content">
        <div className="image7"><img src="assets/images/zoho-solu.jpg" alt=""/></div>
        <div className="main-solutions"><h6>ZOHO Solutions</h6>
        <p>Discover our customized Zoho solutions designed to streamline your business processes and enhance productivity From comprehensive CRM and project 
          management tools to intuitive collaboration and analytics platforms</p></div>
      </div>
    </div>
    <div className="arrow7"><a href="/zoho"><button className="arrow-btn7"><img src="assets/images/arrow_s.png" alt=""/></button></a></div>
    </div>

    </div>
  </div>
  
    </>
  )
}

export default SolutionContents