import React from 'react'
import './BlogAbout.css'

function BlogAbout() {
  return (
    <>
    <div className="container blog">
  <div className="row">
    <div className="col blog_content">
      <h5>Join Our Team and Shape Your Future</h5>
      <p>Welcome to our News and Blog page, your go-to source for the latest updates, insightful commentary, and inspiring stories. Here, we delve into the headlines that matter, bringing you in-depth analysis, diverse perspectives, and thought-provoking articles. Whether you're looking to stay informed about global events, explore new ideas, or simply find a bit of inspiration, our curated content is designed to engage, educate, and enlighten. Join our community of curious minds and never miss a moment of the stories shaping our world</p>
  
    </div>
    </div>


    <div className=" blogs_row">

      <div className=" blog_col">
        <div className="blogImages">
          <img src="assets/images/blog_img1.jpg" alt=""/>
          <div className="icons_1"><img src="assets/images/1.icon1.png" alt="" /></div>
        </div>
        <div className="content_field">
          <h5>Streamlining Manufacturing 
            Operations: The Path to 
            Effective Management 
            and Automation</h5>

            <p>As a solution consultant specializing in MRP implementations, I see many manufacturing companies grappling with a common challenge – evolving their IT systems to keep up with the advancements they need to stay competitive.</p>
         <a href="/news">  <button t className=" view_more">view more</button> </a>
          </div>
      </div>

      <div className="blog_col">

        <div className="blogImages">
          <img src="assets/images/blog_img2.jpg" alt=""/>
          <div className="icons_1"><img src="assets/images/2.icon2.png" alt="" /></div>
        </div>
        <div className="content_field">
          <h5>Streamlining Manufacturing 
            Operations: The Path to 
            Effective Management 
            and Automation</h5>

            <p>As a solution consultant specializing in MRP implementations, I see many manufacturing companies grappling with a common challenge – evolving their IT systems to keep up with the advancements they need to stay competitive.</p>           
          <a href="/news"> <button  className=" view_more">view more</button> </a> 
          </div>
      </div>

      <div className=" blog_col">

        <div className="blogImages">
          <img src="assets/images/blog_img3.jpg" alt=""/>
          <div className="icons_1"><img src="assets/images/1.icon1.png" alt="" /></div>
        </div>
        <div className="content_field">
          <h5>Streamlining Manufacturing 
            Operations: The Path to 
            Effective Management 
            and Automation</h5>

            <p>As a solution consultant specializing in MRP implementations, I see many manufacturing companies grappling with a common challenge – evolving their IT systems to keep up with the advancements they need to stay competitive.</p>
         <a href="/news">  <button  className=" view_more">view more</button> </a>
          </div>
      </div>

    </div>


</div>
    </>
  )
}

export default BlogAbout