import { useState } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function useForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    country: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    country: ''
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
      isValid = false;
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
      isValid = false;
    }

    if (!formData.country.trim()) {
      errors.country = 'Country is required'; // Fixed the message here
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      setLoading(true);
      try {
        const result = await emailjs.send(
          'service_ir3eljh',
          'template_qwddzb6', 
          formData, 
          '4Ne5WItibjK46Jkzl' 
        );

        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          country: '',
        });

        setLoading(false);
        Swal.fire({
          title: 'Success!',
          text: 'Form submitted successfully!',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

      } catch (error) {
        setLoading(false);
        Swal.fire({
          title: 'Error!',
          text: 'Error submitting form. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    }
  };

  return {
    formData,
    formErrors,
    loading,
    handleInputChange,
    handleSubmit
  };
}

export default useForm;
