import React, { useEffect } from 'react'
import './ZohoAbout.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
function ZohoAbout() {
  useEffect(() => {
    
    AOS.init({});
  }, []);
  return (
    <>
        <div className="zoho_authorized">
    <div className="col-9 ZA_col">
      <img src="assets/images/zoho_white.png" data-aos="flip-up" alt=""/>
        <h2>As Zoho  Partner, we Provide Services
          for All Segments of your Marketplace</h2>
        <p id="row_content1">Infomaze, as Zoho partners, offers full-cycle Zoho solution services to all industry verticals, from SMEs to large enterprises. We can build solutions for any industry as long as we have the right inputs.</p>
    </div>

</div>
    </>
  )
}

export default ZohoAbout