import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import CloudBanner from '../CloudBanner/CloudBanner'
import CloudAbout from '../CloudAbout/CloudAbout'
import Future from '../CloudFuture/Future'

function CludPage() {
  return (
   <>
   <div className="wrapper">
   <Navbar/>
   <CloudBanner />
   <CloudAbout/>
   <Future/>
   <Footer/>
   
   </div>
   </>
  )
}

export default CludPage 