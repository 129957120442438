import React, { useEffect } from 'react'
import './OdooAbout.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function OdooAbout() {
  useEffect(() => {
    
    AOS.init({});
  }, []);
  return (
    
    <>
    <div className=" odoo_implementation">
    <div className="col-9 odoo_implementationSection">
      <img src="assets/images/ODOO_logo.png"  data-aos="flip-right" alt=""/>
        <h2>Odoo Implementation: The Ultimate Guide for Your Business</h2>
        <p id="row_content">Odoo ERP system is an efficient open-source software that can be customized and integrated with other systems to meet your specific business needs. It contains a comprehensive suite of applications capable of managing your business operations like sales, finance, human resources, marketing, etc. Odoo offers a single platform where different applications can communicate seamlessly.  Although the Odoo ERP system has in-built applications, often businesses need customizations into the applications to make processes more efficient. Sahara helps you with tailor-made business applications. However, before performing business-specific implementations, we will arrange a consultation with you to understand your challenges. We respect your business ideas and protect them by signing a non-disclosure agreement.</p>
    </div>

</div>
    </>
  )
}

export default OdooAbout