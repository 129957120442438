import React, { useEffect, useState } from 'react'
import './ComputingAbout.css'

function ComputingAbout() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
   <>
   <div className=" cloud8">

<div className="row Cls">
  <div className="col-9 cloud-ser8" >
    <img src="assets/images/cloud-solution.png" className={` ${animate ? 'animate__animated animate__flipInX' : ''}`} alt="" />
      <h2>Elevate your enterprise with our comprehensive range of cloud solutions</h2>
      <p id="para8">Accelerate transformation and unlock the full value of your technology investments. We assist you in 
        navigating diverse cloud options to select the optimal solutions for your business architecture. Harness the potential 
        of enterprise cloud solutions to enhance adaptability and agility within your organization.</p>
  </div>
</div>
</div>
   </>
  )
}

export default ComputingAbout