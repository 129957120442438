import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import Career from './components/CareerPage/Career';
import ContactPage from './components/ContactPage/ContactPage';
import CloudPage from './components/CloudPage/CludPage';
import Solution from './components/SolutionPage/Solution';
import Computing from './components/CloudComputingPage/Computing';
import AboutUsPage from './components/AboutUsPage/AboutUsPage';
import OdooPage from './components/OdooComponents/OdooPage';
import ZohoPages from './components/ZohoComponents/ZohoPages';
import Reloader from './components/PReloader/Reloader';
import BlogPage from './components/BlogComponents/BlogPage';
import NewsPage from './components/NewsDetailsComponents/NewsPage';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ChatBoat from './components/ChattBoat/ChatBoat';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };

    const handleEnd = () => {
      setLoading(false);
    };

    const startLoading = () => {
      handleStart();
    };

    const endLoading = () => {
      handleEnd();
    };

    window.addEventListener('beforeunload', startLoading);
    window.addEventListener('load', endLoading);

    return () => {
      window.removeEventListener('beforeunload', startLoading);
      window.removeEventListener('load', endLoading);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Reloader />
      ) : (
        <BrowserRouter>
             
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/career' element={<Career />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/cloud' element={<CloudPage />} />
            <Route path='/solution' element={<Solution />} />
            <Route path='/cloudcomputing' element={<Computing />} />
            <Route path='/aboutus' element={<AboutUsPage />} />
            <Route path='/odoo' element={<OdooPage />} />
            <Route path='/zoho' element={<ZohoPages />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/news' element={<NewsPage />} />
          </Routes>
          <ChatBoat/>
          <TawkMessengerReact
          propertyId="66ae1ed51601a2195ba05cb4"
          widgetId="1i4c2gsrr"
          />
          
        </BrowserRouter>
      )}
    </>
  );
}

export default App;