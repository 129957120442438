import React from 'react'
import './Future.css'
import FutureTop from './FutureTop/FutureTop'
import FuturBottom from './FuturBottom/FuturBottom'

function Future() {
  return (
   <>
   <div className="future-container">
     <FutureTop/>
     <FuturBottom/>
   </div>

   </>
  )
}

export default Future