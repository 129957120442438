import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import SolutionBanner from '../SolutionBanner/SolutionBanner'
import SolutionContents from '../SolutionContent/SolutionContents'

function Solution() {
  return (
   <>
     <div className="wrapper">
    <Navbar/>
    <SolutionBanner/>
    <SolutionContents/>
    <Footer/>
    </div>
   </>
  )
}

export default Solution