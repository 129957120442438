import React from 'react'
import './footer.css'
function Footer() {
  return (
    <>
    {/* <hr/> */}
  <footer>
      <div><img src="assets/images/logo.png.png" alt="" id="footer-logo"/></div>
    

      <div className="links">
          <div className="menulist-social">
              <div className="footer-menu">
                 <li><a href="/">Home</a></li>
                <li><a href="/aboutus">About</a></li>
                <li><a href="/solution">Solutions</a></li>           
                <li><a href="/career">Careers</a></li>
                {/* <li><a href="/news">Newsroom</a></li> */}
                <li><a href="/blog">Updates</a></li>
                <li><a href="/contact">Contact</a></li>
              </div>
              <div className="socialmedia-icons">
                  <li><a href="#"><img src="assets/images/fb.png" alt="" width="30px"/></a></li>
                  <li><a href="#"><img src="assets/images/instagram.png" alt="" width="25px"/></a></li>
                  <li><a href="#"><img src="assets/images/twitter.png" alt="" width="20px"/></a></li>
                  <li><a href="#"><img src="assets/images/linkedin.png" alt="" width="30px"/></a></li>
              </div>
              
          </div>

          <div className="sent">
            <p>Join our talent community and get regular updates</p>
            <div className="input-group mb-3 input-email">
              <input type="text" className="form-control" style={{fontFamily:'Aileron-Light'}} placeholder="     Enter your email address" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
              <span className="input-group-text" id="basic-addon2"><i className="fa-regular fa-envelope"></i></span>
            </div>
          </div>
      </div>
      <div className="copyright"><p>&copy;2024 Sahara Technologies All Rights Reserved</p></div>
  </footer>
  <hr/>


    </>
  )
}

export default Footer