import React, { useEffect, useState } from 'react'
import './CareerBanner.css'
import 'animate.css';
import 'animate.css/animate.min.css';



function CareerBanner() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  // 
  return (
  <>
    <div className="container-fluid p-0 section2">
    <div className="row ">
      <div className="banner2">
        <div className="col-md-9">
          <div className="content2"  >
            <h1 className={` ${animate ? 'animate__animated animate__slideInRight' : ''}`}><span>CAREERS AT SAHARA</span><br/>DISCOVER YOUR POTTENTIAL</h1>
        </div>
        </div>
        <div className="bar2"></div>
    
    </div>
  </div>
  </div>
  </>
  )
}

export default CareerBanner