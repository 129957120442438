import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './Banner.css'
import 'animate.css';
import 'animate.css/animate.min.css';
import useForm from '../Foms/Form'
import Reloader from '../PReloader/Reloader';







function Banner() {
  const [showPopup, setShowPopup] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [amimatenav, setamimatenav] = useState(false);
  const { formData, formErrors, loading, handleInputChange, handleSubmit } = useForm();



  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    setamimatenav(true);
    setAnimate(true);
    const button = document.querySelector(".navbar-toggler");
    const sideBar = document.querySelector(".sidebar");
    const closeButton = document.querySelector(".btn-close");

    const toggleSidebar = () => {
      sideBar.classList.toggle("active");
    };

    const handleClickOutside = (event) => {
      if (!sideBar.contains(event.target) && !button.contains(event.target) && !closeButton.contains(event.target)) {
        sideBar.classList.remove("active");
      }
    };

    button.addEventListener("click", toggleSidebar);
    closeButton.addEventListener("click", toggleSidebar);
    document.addEventListener("click", handleClickOutside);

    return () => {
      button.removeEventListener("click", toggleSidebar);
      closeButton.removeEventListener("click", toggleSidebar);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="top-header">
        <div className="row">
          <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
              <a className="navbar-brand" href="/"><img src="assets/images/logo.png.png" alt="" id="logo2" /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"  ></i></span>
              </button>
            </div>
          </nav>
        </div>
      </div>


      {/* .............Banner.......................... */}

    
        <div className="container-fluid p-0 ">
          <div className="row ">
          {loading ? (
        <Reloader />
      ) : (
            <div className="banner">
              <div className="col-md-8 ">
                <div className="col-md-3 nav-bar">
                  <ul className={`sidebar ${amimatenav ? 'animate__animated animate__zoomInLeft' : ''}`} >
                    <div className="close-btn"><button type="button" className="btn-close" aria-label="Close"></button></div>
                    <li><a href="/" >Home</a></li>
                    <li><a href="/aboutus">About</a></li>
                    <li><a href="/solution">Solutions</a></li>
                    <li><a href="/career">Careers</a></li>
                    <li><a href="/blog">Updates</a></li>
                    <li><a href="/contact">Contact</a></li>
                  </ul>
                </div>
                <div className="col-md-9">
                  <div className={`content ${animate ? 'animate__animated animate__fadeInRight' : ''}`}>
                    <h1><span >EMPOWERING<br />BUSINESS</span><br />THROUGH<br /> <span id='digitamarket'> DIGITAL<br />TRANSFORMATION </span></h1>
                  </div>
                </div>
              </div>
              <div className="bnnrImg">
                <img src="assets/images/bannerms.jpg" alt="" data-aos="fade-in" />
              </div>
              <div className={`free-consultation ${showPopup ? 'show' : ''}`}>
                <div className="popup">
                  <div className="cancel"><button className="hide_arrow" onClick={togglePopup}><img src="assets/images/hide-arrow.png" alt="" /></button></div>
                  <div className="headp">
                    <h6>Get a free consultation today!</h6>
                    <p>Our experts are ready to provide personalized advice and solutions tailored to your needs</p>
                  </div>

                  <div className="formbox">
                    <div className="freeConsultation-form">

                      <form onSubmit={handleSubmit} className='popupform' >
                        <div className="popupbox">
                          <input type="text" required name="name" value={formData.name} onChange={handleInputChange} id="input-field"  />
                          <span>Name</span>
                          <div><img src="assets/images/user.png" alt="" width="20px" /></div>
                        </div>
                        <div className="popupbox">
                          <input type="text" required name="email" value={formData.email} onChange={handleInputChange} id="input-field" />
                          <span>Email</span>
                          <div><img src="assets/images/email.png" alt="" width="20px" /></div>
                        </div>
                        <div className="popupbox">
                          <PhoneInput
                            country={'ae'}
                            required
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={(value) => handleInputChange({ target: { name: 'phoneNumber', value } })}
                            inputProps={{ id: 'input-field', required: true }}
                            containerClass="phone-input-custom"
                          />
                          
                          <div><img src="assets/images/phone.png" alt="" width="20px" /></div>
                          
                        </div>
                        {formErrors.phoneNumber && <small className="error" style={{ color: 'red' }}>{formErrors.phoneNumber}</small>}
                        {/* <div className="popupbox">
                          <input type="text" required name="country" value={formData.country} onChange={handleInputChange} id="input-field" />
                          <span>Leave Us a Few words</span>
                          <div><img src="assets/images/country.png" alt="" width="20px" /></div>
                        </div> */}
                        <div className="popupbox query">
                          <input type="text" required name="country" value={formData.country} onChange={handleInputChange} id="input-field" className="text-areap" />
                          <span>Leave Us a Few words</span>
                          <div style={{ padding: '8px 0' }}> <i className="fa-solid fa-bars lines"></i></div>
                        </div>
                        <div className="sent-btn">
                          <button className="sentBtn"><img src="assets/images/sent-icon.png" alt="" /></button>
                        </div>
                      </form> 

                    </div>
                  </div>

                </div>
                <button className={`BtnSection ${showPopup ? 'hide' : ''}`} onClick={togglePopup}>Free Consultation</button>
              </div>
           
       
             </div>
            )} 
          </div>
        </div>


     





    </>
  )
}

export default Banner


