import React, { useEffect, useState } from 'react'
import './ComputingBanner.css'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import 'animate.css';
import 'animate.css/animate.min.css';
import useForm from '../Foms/Form'
import Reloader from '../PReloader/Reloader';


function ComputingBanner() {
  const [animate, setAnimate] = useState(false)
  const { formData, formErrors, loading, handleInputChange, handleSubmit } = useForm();

  useEffect(() => {
    setAnimate(true);
  }, [])
  return (
    <>
      <div className="container-fluid p-0 section8">
        <div className="row ">
          <div className="banner8">
            <div className="col-md-8">
              <div className="main-content8">
                <div className="side-bar8">
                  <div className="line8"></div>
                  <div className="circle8">
                    <div className="black-circle8"></div>
                  </div>
                  <div className="line1-8"></div>
                </div>
                <div className="content8" >
                  <h1 className={` ${animate ? 'animate__animated animate__lightSpeedInRight' : ''}`}>Securely delivered managed cloud <br /> solutions for outcome-based <br />modernization initiatives</h1>
                  <h5>Unleash innovation with tailored cloud solutions.</h5>
                  <p>Cloud technology has emerged as a transformation catalyst,
                    yet enterprises face significant barriers in expanding its use
                    and demonstrating its value in driving business outcomes.</p>

                  <ul className="list8">
                    <li className={` ${animate ? 'animate__animated animate__bounceInLeft' : ''}`}><img src="assets/images/tick2.png" alt="" />Reduce risk</li>
                    <li className={` ${animate ? 'animate__animated animate__bounceInLeft' : ''}`}><img src="assets/images/tick2.png" alt="" />Secure</li>
                    <li className={` ${animate ? 'animate__animated animate__bounceInLeft' : ''}`}><img src="assets/images/tick2.png" alt="" />Manage</li>
                  </ul>
                </div>
              </div>

              {/* <div className="bar8"></div> */}
            </div>

            <div className="">
              <div className="heroForm">
                {loading ? (
                  <Reloader />
                ) : (
                  <form  onSubmit={handleSubmit} className='form8'>
                    <h6>Transform Your Business Now</h6>
                    <div className="input-box8">
                      <input
                        type="text"
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Full Name</span>
                      <div><img src="assets/images/user.png" alt="" width="20px" /></div>
                    </div>

                    <div className="input-box8">
                      <input
                        type="text"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>email</span>
                      <div><img src="assets/images/email.png" alt="" width="20px" /></div>
                    </div>

                    <div className="input-box8">
                      <PhoneInput
                        country={'ae'}
                        required
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(value) => handleInputChange({ target: { name: 'phoneNumber', value } })}
                        inputProps={{ id: 'input-field', required: true }}
                        containerClass="phone-input-custom"
                      />

                      <div><img src="assets/images/phone.png" alt="" className='phoneicon' width="20px" /></div>
                    </div>
                    {formErrors.phoneNumber && <small className="error" style={{ color: 'red' }}>{formErrors.phoneNumber}</small>}

                    <div className="input-box8">
                      <input
                        type="text"
                        required
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        id="input-field"
                      />
                      <span>Leave Us a Few words</span>
                      <div> <i className="fa-solid fa-bars lines"></i></div>
                    </div>

                    <div className="button-sec8">
                      <button className="btn btn-primary submit8"  >Sent Enquiry</button>
                    </div>
                  </form>
                )}
              </div>
            </div>
    
          </div>
        </div>
      </div>
    </>
  )
}

export default ComputingBanner