import React, { useEffect } from 'react'
import '../OdooImplementation.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountDown from '../../CountDown/CountDown';

function KeyComponents() {
  useEffect(() => {
    
    AOS.init({});
  }, []);
  return (
    <>
     <div className="keyComponents">
            <div className="row3-1">

                <div className="col1-1">
                    <p className="number"  data-aos="fade-down-right">1</p>
                    <div className="row_col">
                      <h3>Project Management</h3>
                      <p>The first step is to define the scope, objectives, deliverables, timeline, budget, roles, and responsibilities of your Odoo implementation project. You should also establish a clear communication plan and a risk management plan to ensure that your project runs smoothly and meets your expectations. </p>
                    </div>
                </div>

                <div className="col1-1">
                  <p className="number"  data-aos="fade-down-right">2</p>
                  <div className="row_col">
                    <h3>Workflow Analysis</h3>
                    <p>The next step is to analyze your current business processes and workflows and identify the gaps, pain points, opportunities, and requirements for improvement. You should also map out your desired future scope and define the key performance indicators (KPIs) that will measure the success of your project. </p>                  </div>
              </div>

              <div className="col1-1">
                <p className="number"  data-aos="fade-down-right">3</p>
                <div className="row_col">
                  <h3>Odoo Modules Selection</h3>
                  <p>Based on your workflow analysis, you should select the appropriate Odoo modules that will meet your business needs and project requirements. Modules should be prioritized based on their relevance to your project. </p>                </div>
            </div>

            <div className="col1-1">
              <p className="number"  data-aos="fade-down-right">4</p>
              <div className="row_col">
                <h3>Change Management</h3>
                <p>Implementation of Odoo involves changes in your organization’s culture, structure, policies, procedures, systems, and employee's daily processes. Prepare for these changes by creating a change management strategy that will address the potential challenges and risks, such as resistance, confusion, anxiety, or loss of productivity. Communicating the benefits and value of change to your stakeholders and users and providing them with adequate training and support can alleviate potential challenges. Implementing a change management methodology aims to maximize project success. </p>              </div>
          </div>

          <div className="col1-1 col-className">
            <p className="number"  data-aos="fade-down-right">5</p> 
            <div className=" row_col row_col">
              <h3>Consultant Selection</h3>
              <div className="row_div">
              <p>Successful Odoo Implementation also depends upon choosing the right Odoo implementation partner. It is crucial for the success of your project. You should look for a partner with the following qualities: </p>            
                <li>Odoo Implementation Partner must have experience in implementing Odoo for similar businesses or industries as yours.</li>
                <li>Your Odoo Partner must have a proven track record of delivering quality results on time and within budget.</li>
                <li>To implement Odoo, the implementation partner must have a team of qualified and certified Odoo professionals offering comprehensive services from consulting to development to support.</li>
                <li>Your Odoo Implementation Partner must understand your business goals and vision, and listen to your feedback as well.</li>
                <li>They must provide transparent pricing, follow best practices, respect your confidentiality, value long-term relationships with your company, and develop trust by sharing references.</li>
                <li>An Odoo Implementation Partner must have a good reputation regarding their work and must be flexible in their approach aligned with your business requirements.</li>
            </div>
            
            </div>        
            </div>
            
          </div>

        

          <div className="contact-btnodo">
          <a href="/contact">   <button type="button" className="btn btn-primary btn-lg contactodo">CONTACT US NOW</button> </a>
          </div>
 </div>
    </>
  )
}

export default KeyComponents