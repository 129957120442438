import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import NewDetailsBanner from './NewsDetailsbanner/NewDetailsBanner'
import NewsAbout from './NewsAbout/NewsAbout'



function NewsPage() {
  return (
 <>
   <div className="wrapper">
   <Navbar/>
 <NewDetailsBanner/>
 <NewsAbout/>

   <Footer/>
   </div>

 </>
  )
}

export default NewsPage