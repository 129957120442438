import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import ZohoBanner from './ZohoBanner/ZohoBanner'
import ZohoAbout from './ZohoAbout/ZohoAbout'
import ZohoService from './ZohoService/ZohoService'
import ZohoSolutionpage from './ZohoSolutionPage/ZohoSolutionpage'

function ZohoPages() {
  return (
   <>
   <div className="wrapper">
    <Navbar/>
      <ZohoBanner/>
      <ZohoAbout/>
      <ZohoService/>
      <ZohoSolutionpage/>
    <Footer/>
   </div>
   </>
  )
}

export default ZohoPages