import React, { useEffect, useState } from 'react'
import './SolutionBanner.css'
import 'animate.css';
import 'animate.css/animate.min.css';


function SolutionBanner() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
   <>
   
  <div className="container-fluid p-0 section7">
    <div className="row ">
      <div className="banner7">
        <div className="col-md-9">
          <div  className={`content7 ${animate ? 'animate__animated animate__slideInRight' : ''}`} >
            <h1><span>sahara</span><br/>Innovative Solutions for <br/>
              a Connected World
              </h1>
        </div>
        </div>
        <div className="bar7"></div>
       
    </div>
  </div>
</div>
   </>
  )
}

export default SolutionBanner