import React, { useState } from 'react'
import './Navbar.css'


function Navbar() {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [sidebarHide, setSidebarHide] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  const closeSidebar = () => {
    setSidebarHide(!sidebarHide);
  };
  return (
    <>


<div className="top-header2">
      <div className="row">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid nav-section">
            <a className="navbar-brand" href="/"><img src="assets/images/logo.png.png" alt=""  id="logo"/></a>
          </div>
        </nav>
      </div>
              <ul className={`nav justify-content-center ${sidebarActive ? 'active' : ''} ${sidebarHide ? 'hide' : ''}`}>
                <div className="close-btn"><button type="button" className="btn-close" id='btn-close' onClick={toggleSidebar} aria-label="Close"></button></div>
                <li className="nav-item2">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item2">
                  <a className="nav-link "  href="/aboutus">About</a>
                </li>
                <li className="nav-item2">
                  <a className="nav-link" href="/solution">Solutions</a>
                </li>
               
                <li className="nav-item2">
                  <a className="nav-link" href="/career">Careers</a>
                </li>
                <li className="nav-item2">
                  <a className="nav-link" href="/blog">Updates</a>
                </li>
                <li className="nav-item2">
                  <a className="nav-link" href="/contact">Contact</a>
                </li>
              </ul>
              <div className="custNav">
              <a href="/contact"> <button className="contact_icon"><img src="assets/images/contact_icon.png" alt=""/></button></a>

<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleSidebar}>
  <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
</button>
              </div>
           
         
  </div>


    </>
  )
}

export default Navbar