import React from 'react'
import './Newsabout.css'


function NewsAbout() {
  return (
<>
<div className="container news-details">
  <div className="row">
    <div className="col newsDetails_content">
      <h5>Streamlining Manufacturing Operations: The Path to 
        Effective Management and Automation</h5>
      <p>Welcome to our News and Blog page, your go-to source for the latest updates, insightful commentary, and inspiring stories. Here, we delve into the headlines that matter, bringing you in-depth analysis, diverse perspectives, and thought-provoking articles. Whether you're looking to stay informed about global events, explore new ideas, or simply find a bit of inspiration, our curated content is designed to engage, educate, and enlighten. Join our community of curious minds and never miss a moment of the stories shaping our world</p>
  
    </div>

    <div className="management">
        <img src="assets/images/management_img.jpg" alt=""/>
    </div>

    <div className="management-details">
      <p>Welcome to our News and Blog page, your go-to source for the latest updates, insightful commentary, and inspiring stories. Here, we delve into the headlines that matter, bringing you in-depth analysis, diverse perspectives, and thought-provoking articles. Whether you're looking to stay informed about global events, explore new ideas, or simply find a bit of inspiration, our curated content is designed to engage, educate, and enlighten. Join our community of curious minds and never miss a moment of the stories shaping our world</p>
      <p>Welcome to our News and Blog page, your go-to source for the latest updates, insightful commentary, and inspiring stories. Here, we delve into the headlines that matter, bringing you in-depth analysis, diverse perspectives, and thought-provoking articles. Whether you're looking to stay informed about global events, explore new ideas, or simply find a bit of inspiration, our curated content is designed to engage, educate, and enlighten. Join our community of curious minds and never miss a moment of the stories shaping our world Welcome to our News and Blog page, your go-to source for the latest updates, insightful commentary, and inspiring stories. Here, we delve into the headlines that matter, bringing you in-depth analysis, diverse perspectives, and thought-provoking articles. Whether you're looking to stay informed about global events, explore new ideas, or simply find a bit of inspiration, our curated content is designed to engage, educate, and enlighten. Join our community of curious minds and never miss a moment of the stories shaping our world</p>
    </div>
    </div>

</div>
</>
  )
}

export default NewsAbout