import React from 'react';
import './chatBoat.css';

const ChatBoat = () => {
    const handleClick = () => {
        
        window.open('https://wa.me/+971504852446', '_blank');
      };


  return (
    <>
      <button className="open-btn" onClick={handleClick}>
        <img src="assets/images/whatsapp_icon.png" alt="WhatsApp Icon" className="open-whatsapp-icon" />
      </button>
    </>
  );
};

export default ChatBoat;
