import React from 'react'
import '../ZohoSolution.css'

function ZohoWorks() {
  return (
   <>
   <div className="how_We_Work">
  <div className="col-9 Work_Section">
      <h1>How we Work</h1>
      <p >Our Zoho certified developers deeply understand your business workflow and customize the Zoho apps accordingly. Be it for 
        sales and marketing, customer service solutions, finance, IT, or custom solutions, we provide Zoho customizations that automate 
        your businesses efficiently. Every project starts with an elaborate discussion, strategy, setup, testing, and implementation with 
        complete transparency.</p>
  </div>
  <div className="triangle1"></div>
</div>
   </>
  )
}

export default ZohoWorks