import React, { useEffect, useState } from 'react'
import './NewDetailsBanne.css'
import 'animate.css';
import 'animate.css/animate.min.css';


function NewDetailsBanner() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
   <>
    <div className="container-fluid p-0 section11">
    <div className="row ">
      <div className="banner11">
        <div className="col-md-9">
          <div   className={`content11 ${animate ? 'animate__animated animate__slideInRight' : ''}`}>
            <h1><span>Informed Minds, Inspired Lives</span><br/>News and blogs with Depth</h1>
        </div>
        </div>
        <div className="bar11"></div>

    </div>
  </div>
</div>
   </>
  )
}

export default NewDetailsBanner