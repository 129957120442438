import React, { useEffect } from 'react';
import './Rooms.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation , Autoplay , Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide,  } from 'swiper/react';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Rooms() {

  useEffect(() => {
    AOS.init({
      duration: 3000, // Animation duration
      loop: true, 
    });
  }, []);
  return (
    <>
  
      <div className="newsroom ">
        <div className="doted-images" data-aos="zoom-in">
          <div className="animation">
            <div className="dot-animation">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
              <div className="dot dot6"></div>
              <div className="dot dot7"></div>
            </div>
            <div className="dot-animation">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
              <div className="dot dot6"></div>
              <div className="dot dot7"></div>
            </div>
            <div className="dot-animation">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
              <div className="dot dot6"></div>
              <div className="dot dot7"></div>
            </div>
            <div className="dot-animation">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
              <div className="dot dot6"></div>
              <div className="dot dot7"></div>
            </div>
            <div className="dot-animation">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
              <div className="dot dot6"></div>
              <div className="dot dot7"></div>
            </div>
          </div>
        </div>
        <div className="newsroom-sec">
          <div className="news">
            <h6>NEWS ROOM</h6>
            <h1 data-aos="fade-up"
    >STAY AHEAD WITH <br/>TECH UPDATES</h1>
          </div>
          <div className="service-content" data-aos="fade-up"
     >
            <img src="assets/images/arrow.png"  alt="" className="arrowimage"/>
          </div>
        </div>

        <div className="sahara-announcement">
          <img src="assets/images/sahara.png" className='sahara' alt="" />
          <Swiper
               modules={[Navigation, Pagination, Autoplay]}
               navigation
               loop={true}
                 autoplay={{ delay: 5000 }}
            className="swiper sahara-technologies"
          >
            <SwiperSlide className="swiper-slide">
              <div className="new-project" >
                <h1 className='h1'>Sahara Technologies announces release of next generation project management tool</h1>
                <p>A leading provider of innovative software solutions, is proud to announce the release of their latest product, Projectmgt 2.O.
                  This next-generation project management tool is poised to revolutionize the way teams collaborate, plan, and execute project.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="new-project">
                <h1 className='h1'>Sahara Technologies announces release of next generation project management tool</h1>
                <p>A leading provider of innovative software solutions, is proud to announce the release of their latest product, Projectmgt 2.O.
                This next-generation project management tool is poised to revolutionize the way teams collaborate, plan, and execute project.
                </p>
              </div>
            </SwiperSlide>
        
          </Swiper>
        </div>
      </div>

       <div className="zag"><img src="assets/images/zigzag.png"  alt=""/></div>
    </>
  );
}

export default Rooms;
