import React, { useEffect, useState } from 'react'
import '../ZohoSolution.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import 'animate.css/animate.min.css';

function ZohoSolution() {
  const [animate , setAnimate] = useState(false)
  useEffect(() => {
    setAnimate(true);
    AOS.init({});
  }, []);
  return (
   <>
   <div className="zoho_solutions">
  <div className="rowSolu1">
    <h1>sahara as Zoho Partner has worked on Multiple <br/> Zoho Solutions</h1>
    <p>Out of the many solutions available on the Zoho platform, Sahara as a Zoho  partner offers solutions that let you streamline your everyday business. Our Zoho certified developers provide customizations for all solutions to efficiently align and automate your workflow process. Among the many, check out the few Zoho solutions that we have worked on below.</p>
     

    <div className="container text-center solutionsCont">
      <div className="row g-2 colZoho">

          <div className="colms2">

              <div className="colm1"  data-aos="zoom-in">
                    <div className="SL-ct">
                      <h3>Zoho People</h3>
                      <p>Our developers automate all your HR operations by integrating your employee data and help you track their attendance, performance, and manage appraisals, and more.</p>
                    </div>
                    <div className="zoho_img"><img src="assets/images/zoho_people.jpg" alt="" className="ZImg"/></div>
              </div>

              <div className="colm1"  data-aos="zoom-in">
                <div className="SL-ct">
                  <h3>ZOHO Books</h3>
                  <p>Our developers integrate small and medium enterprise third-party accounting applications with Zoho Books for an accounting solution that tracks expenses and automates transactional feed updates in your bank accounts.</p>
                </div>
                <div className="zoho_img"><img src="assets/images/zoho_book.jpg" alt="" className="ZImg"/></div>
              </div>

          </div>

          <div className="colms2">

            <div className="colm1"  data-aos="zoom-in">
                  <div className="SL-ct">
                    <h3>ZOHO CRM</h3>
                    <p>Our developers customize, integrate Zoho CRM add ons, and migrate data from your legacy applications to Zoho CRM and help you in managing and preventing loss of your leads, prospects, or loyal customers.</p>
                  </div>
                  <div className="zoho_img"><img src="assets/images/zoho_CRM.jpg" alt="" className="ZImg"/></div>
            </div>

            <div className="colm1"  data-aos="zoom-in">
              <div className="SL-ct">
                <h3>Zoho Analytics</h3>
                <p>Getting useable reports and analytics that can be viewed on a single dashboard in real-time is possible as our developers carry out successful data warehousing in almost no time.</p>
              </div>
              <div className="zoho_img"><img src="assets/images/zoho_analytics.jpg" alt="" className="ZImg"/></div>
            </div>

        </div>

        
        <div className="colms2"  data-aos="zoom-in"> 

          <div className="colm1">
                <div className="SL-ct">
                  <h3>ZOHO Creator</h3>
                  <p>Using this platform which requires less or no code for developing mobile as well as web applications with break-neck speed, our Zoho Creator developers assign custom business rules and workflows.</p>
                </div>
                <div className="zoho_img"><img src="assets/images/zoho_creator.jpg" alt="" className="ZImg"/></div>
          </div>

          <div className="colm1 "  data-aos="zoom-in">
            <div className="SL-ct">
              <h3>Zoho One</h3>
              <p>We come up with unique Zoho One Solutuions to help you manage your work order process better and increase your business efficiency using the one-stop shop for all major Zoho applications included in a single package.</p>
            </div>
            <div className="zoho_img"><img src="assets/images/zoho_one.jpg" alt="" className="ZImg"/></div>
          </div>

      </div>

          <div className="colms2">

            <div className="colm1"  data-aos="zoom-in">
              <div className="SL-ct">
                <h3>Zoho Inventory</h3>
                <p>Zoho inventory management software is popular among startups and SMBs that helps gain productivity and efficiency in their inventory system through automation, and businesses can remain at the top of their competitors and investors by making intelligent decisions.
                </p>
              </div>
              <div className="zoho_img"><img src="assets/images/zoho_inventory.jpg" alt="" className="ZImg"/></div>
            </div>

            <div className="colm1"  data-aos="zoom-in">
                  <div className="SL-ct">
                    <h3>Zoho Sites</h3>
                    <p>Zoho Sites is a convenient and foolproof website builder that allows prospects to build top-notch websites efficiently. As a Zoho partner, we help clients to install and provide support to use this solution which can be used without any experience in IT skills or coding.</p>
                  </div>
                  <div className="zoho_img"><img src="assets/images/zoho_sites.jpg" alt="" className="ZImg"/></div>
            </div>

        </div>

        <div className="colms2">

          <div className="colm1"  data-aos="zoom-in">
            <div className="SL-ct">
              <h3>Zoho Expense</h3>
              <p>Zoho Expense is the most widely used online expense reporting software, which streamlines your financial transactions to quicken revenue generations in no time. This platform simplifies approvals, gives quick reimbursements, and automates expense reporting.
              </p>
            </div>
            <div className="zoho_img"><img src="assets/images/zoho_expense.jpg" alt="" className="ZImg"/></div>
          </div>

          <div className="colm1" data-aos="zoom-in">
                <div className="SL-ct">
                  <h3>Zoho Social</h3>
                  <p>Get the intelligent and innovative social media management platform to streamline your business and harness the power of social networks. This software enhances the online presence and allows businesses to connect with the targetted audiences.</p>
                </div>
                <div className="zoho_img"><img src="assets/images/zoho_social.jpg" alt="" className="ZImg"/></div>
          </div>

      </div>

        <div className="colms2">

          <div className="colm1"  data-aos="zoom-in">
            <div className="SL-ct">
              <h3>Zoho Recruit</h3>
              <p>Zoho Recruit is a powerful recruiting management platform and an applicant tracking system for any business. Its user-friendly dashboard and various customizable functionalities allow for streamlining your hiring process.
              </p>
            </div>
            <div className="zoho_img"><img src="assets/images/zoho_recruit.jpg" alt="" className="ZImg"/></div>
          </div>

          <div className="colm1"  data-aos="zoom-in">
                <div className="SL-ct">
                  <h3>Zoho Desk</h3>
                  <p>It is a popular support desk platform that aids businesses in delivering first-in-className customer support to clients with the right tools. Its analytics features help managers to determine operational sectors and enhance productivity.</p>
                </div>
                <div className="zoho_img"><img src="assets/images/zoho_desk.jpg" alt="" className="ZImg"/></div>
          </div>

      </div>


      </div>
    </div>
        
  </div>    
</div>

   </>
  )
}

export default ZohoSolution