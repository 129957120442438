import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import AboutusBanner from '../AboutusBanner/AboutusBanner'
import AboutusStory from '../AboutusStory/AboutusStory'
import AboutusTarget from '../AboutusTarget/AboutusTarget'

function AboutUsPage() {
  return (
    <>
    <div className="wrapper">
    <Navbar/>
    <AboutusBanner/>
    <AboutusStory/>
    <AboutusTarget/>
    <Footer/>
    </div>
    </>
  )
}

export default AboutUsPage