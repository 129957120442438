import React, { useEffect, useState } from 'react'
import './CareerFeatures.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import 'animate.css/animate.min.css';


function CareerFeatures() {
  const [animate, setAnimate] = useState(false);


  useEffect(() => {
    setAnimate(true);
    AOS.init({});
  }, []);
 
  return (
    <>
    <div className="container features-are2">
  <div className="row">
    <div className="col future2">
      <h5>Why Work With Us?</h5>
      <ul className="list-group" id='list-group2'>

        <li className="list-group-item disabled" aria-disabled="true">
          <img src="assets/images/innovative.png" alt=""  data-aos="fade-up-right"/>
          <div id="desc">
            <h4>Innovative Culture: </h4>
            <p>We embrace creativity and encourage our employees to think outside the box. Your ideas and innovations are valued here.</p>
          </div>
        </li>

        <li className="list-group-item">
          <img src="assets/images/career-dev.png"  data-aos="fade-up-right" alt=""/>
          <div id="desc">
            <h4>Career Development: </h4>
            <p>We invest in our employees' professional growth through continuous learning opportunities, mentorship programs, and clear career progression paths.</p>
          </div>
        </li>

        <li className="list-group-item">
          <img src="assets/images/collaborative.png"  data-aos="fade-up-right" alt=""/>
          <div id="desc">
            <h4>Collaborative Environment: </h4>
            <p>Work alongside talented professionals in a supportive and team-oriented atmosphere.</p>
          </div>
        </li>

        <li className="list-group-item">
          <img src="assets/images/benefits.png"  data-aos="fade-up-right" alt=""/>
          <div id="desc">
            <h4>Comprehensive Benefits: </h4>
            <p>We offer competitive salaries, health benefits, retirement plans, and more to ensure our employees are well taken care of.</p>
          </div>
        </li>

        <li className="list-group-item">
          <img src="assets/images/work-life balance.png"  data-aos="fade-up-right" alt=""/>
          <div id="desc">
            <h4>Work-Life Balance: </h4>
            <p>We understand the importance of balance and provide flexible working arrangements to help you maintain a healthy work-life harmony.</p>
          </div>
        </li>

        <li className="list-group-item">
          <img src="assets/images/diversity.png"  data-aos="fade-up-right" alt=""/>
          <div id="desc">
            <h4>Diversity and Inclusion:</h4>
            <p>We are committed to creating a diverse and inclusive workplace where everyone feels respected and valued.</p>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>
    </>
  )
}

export default CareerFeatures