import React from 'react'
import '../OdooImplementation.css'
import CountDown from '../../CountDown/CountDown'

function OdooDeployment() {
  return (
  <>
 
  <div className="odoo_deployment">
  <div className="col-9 odoo_deploymentSection">
   
      <h1>Odoo Deployment process </h1>
      <p >Odoo deployment is setting up Odoo in a production environment or on an internet-facing server. 
        To make the software ready for deployment, complete User Acceptance Testing is performed. Once it is approved by the client or SPoC, it is sent to the production team for deployment to make it available for the live environment. Simple end-user configuration changes will be made to the deployed environment to complete the entire process. 
        After a successful Odoo deployment, Sahara monitors its performance, user adoption, and any other issues. Our team also addresses any post-deployment challenges promptly.</p>
  </div>
  <div className="triangle"></div>
</div>
  </>
  )
}

export default OdooDeployment