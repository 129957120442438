import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

function WhyZoho() {
  useEffect(() => {
   
    AOS.init({});
  }, []);
  return (
   <>
   <div className="container_last">
    <div className="row_whyChoose">
        <h1>Why choose SAHARA,<br/>
          a Zoho  Partner?</h1>
          <div className="col_choose">
            <div className="parner_img"><div className="bar"></div><img src="assets/images/image1.jpg" data-aos="zoom-in-down" alt=""/></div>
            <div className="listing_contents">
              <li><div><img src="assets/images/tick.png" alt=""/></div>Get certified Zoho developers.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Avail Zoho Consultation services for FREE of cost with no obligation.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Our developers have expertise in various industry verticals.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Request for demo trails is available.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Secure solutions with stringent NDAs signed</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Timely delivery and competitively less turnaround time.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>First-className, SaaS, cloud-based solutions that suit your business needs.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Personalized Zoho solutions to match your business workflow.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Updated on the latest features and technologies.</li>
              <li><div><img src="assets/images/tick.png" alt=""/></div>Solutions that help you maximize productivity and achieve exponential growth.</li>
            </div>
          </div>
    </div>

    
    <div className="contact-btn9">
    <a href="/contact">   <button type="button" className="btn btn-primary btn-lg contact9">CONTACT US NOW</button></a>
    </div>
</div>
   </>
  )
}

export default WhyZoho