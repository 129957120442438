import React, { useEffect, useState } from 'react'
import '../Future.css'


function FutureTop() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
  <>
     <h1 className={` ${animate ? 'animate__animated animate__zoomIn' : ''}`}>Protecting Your Digital Frontier, <br/> Safeguarding Your Future</h1>
    <p>In today's business landscape, mitigating risks, fostering trust, and securing digital operations are paramount priorities to 
      support organizational goals and minimize the impact of cybersecurity disruptions.</p>
      <p>Sahara empowers businesses to confidently embrace the future by cultivating operational resilience, fostering trust, 
        and managing enterprise risks. We achieve this through our integrated security and resiliency framework, extensive expertise 
        in cyber resilience and regulatory compliance, and deep experience in safeguarding complex, mission-critical systems.</p>
        
        
        <div className=" future-content">
              <ul className="future-list">
                <li><img src="assets/images/dashline.png" alt=""/> Zero Trust Security Never Trust Always Verify</li>
                <li><img src="assets/images/dashline.png" alt=""/> Security Information & Event Management</li>
                <li><img src="assets/images/dashline.png" alt=""/> Network Detection and Response </li>
                <li><img src="assets/images/dashline.png" alt=""/> Extended Detection and Response</li>
                <li><img src="assets/images/dashline.png" alt=""/> Vulnerability Assessment Solutions</li>
                <li><img src="assets/images/dashline.png" alt=""/> Intrusion Detection System </li>
              </ul>
        
              <ul className="future-list">
                <li><img src="assets/images/dashline.png" alt=""/> User and Entity Behavior Analytics </li>
                <li><img src="assets/images/dashline.png" alt=""/> Network Behaviour Anomaly Detection </li>
                <li><img src="assets/images/dashline.png" alt=""/> SOAR Technology</li>
                <li><img src="assets/images/dashline.png" alt=""/> Network Threat Analysis</li>
                <li><img src="assets/images/dashline.png" alt=""/> Endpoint Detection & Response</li>
                <li><img src="assets/images/dashline.png" alt=""/> Advanced SIEM </li>
              </ul>
                  
          </div>
  </>
  )
}

export default FutureTop