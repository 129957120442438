import React, { useState, useEffect } from 'react';
import './coundown.css';

function CountDown() {
  const calculateTimeLeft = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 7, 0, 0); // 7 AM today
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0, 0); // 2 PM today

    if (now < start || now >= end) {
      // Outside of the 7 AM to 2 PM window
      return null;
    }

    const timeDifference = end - now; // Time difference in milliseconds
    return Math.floor(timeDifference / 1000); // Convert to seconds
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (newTimeLeft === null) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (timeLeft === null) {
    return null;
  }

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(timeLeft);

  return (

    <div className="countdown">
  <div className="timer-img"><img src="assets/images/timer.png" alt=""/></div>
  <h2>Exclusive discount <br/> will expires soon...</h2>
  <div id="clockdiv">
    <div >
      <span className="hours">{hours}</span>
      <div className="smalltext">Hours</div>
    </div>
    <div>
      <span className="minutes">{minutes}</span>
      <div className="smalltext">Minutes</div>
    </div>
    <div>
      <span className="seconds">{seconds}</span>
      <div className="smalltext">Seconds</div>
    </div>
  </div>
</div>



  );
}

export default CountDown;
