import React from 'react'
import Navbar from '../Header/Navbar'
import CareerBanner from '../CareerBanner/CareerBanner'
import CareerAbout from '../CareerAbout/CareerAbout'
import CareerFeatures from '../CareerFeat/CareerFeatures'
import Footer from '../Footer/Footer'


function Career() {
  return (
    <>
     <div className="wrapper">
   <Navbar/>
   <CareerBanner/>
   <CareerAbout/>
   <CareerFeatures/>
   <Footer/>
   </div>
   </>
  )
}

export default Career