import React, { useEffect } from 'react'
import './AboutusStory.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import 'animate.css/animate.min.css';


function AboutusStory() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
 <div className="container about_home">
  <div className="row">
    <div className="col about_ST">
      <h5>About Sahara Technologies</h5>
      <p>Welcome to Sahara Technologies, where technology meets innovation. Since our inception, we have been at the forefront of delivering comprehensive IT solutions that drive business success and transform the way our clients operate. Our team of dedicated professionals brings together expertise, creativity, and a passion for technology to solve complex challenges and unlock new opportunities.</p>
    
      <div className="ST_story">
      <h6>Our story</h6>
      
      <p>Founded in 2023, Sahara Technologies was established with a singular vision: to empower businesses through innovative technology solutions.
         From our humble beginnings, we have grown into a leading IT service provider, serving a diverse range of industries worldwide. Our journey 
         has been marked by continuous learning, adaptation, and a commitment to staying ahead of the technological curve.
      </p>

      <div className="container overflow-hidden text-center">
        <div className="row gx-7 rowMissionVision">
          <div className="col MvContainer" data-aos="fade-right">
          
           <div className="p-3 MV_COntent" >
            
            <div className="mv_img"><img src="assets/images/mission.png" alt=""/></div>
            <div className="define">
            <h4>Mission</h4>
            <p>To deliver cutting-edge technology solutions that empower businesses to achieve their full potential.
               We are committed to innovation, excellence, and customer satisfaction, providing reliable and tailored IT services that drive 
               efficiency, enhance security, and foster growth. By staying ahead of technological trends and maintaining a customer-centric 
               approach, we aim to be the trusted partner for organizations navigating the digital landscape.</p>
               </div>
           </div>
          </div>
          <div className="col MvContainer" data-aos="fade-left">
          
            <div className="p-3 MV_COntent">
              
              <div className="mv_img">
                
                <img src="assets/images/vision.png" alt=""/></div>
                <div className="define">
              <h4>Vison</h4>
              <p>Our vision is to be a global leader in technology innovation, driving transformative change across 
                industries. We aspire to create a future where seamless and secure digital experiences are accessible to all, empowering 
                businesses and individuals to thrive in an interconnected world. Through our commitment to excellence, continuous learning, 
                and ethical practices, we aim to set new standards in the IT industry and make a lasting impact on society.</p></div>
             </div>
             </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </div>
  
    </>
  )
}

export default AboutusStory