import React, { useEffect, useState } from 'react'
import './AboutusBanner.css'
import 'animate.css';
import 'animate.css/animate.min.css';


function AboutusBanner() {
  const [animate , setAnimate] = useState(false)

  useEffect(()=>{
  setAnimate(true);
  }, [])
  return (
    <>
      <div className="container-fluid p-0 section9">
    <div className="row ">
      <div className="banner9">
        <div className="col-md-9">
          <div  className={`content9 ${animate ? 'animate__animated animate__slideInRight' : ''}`}>
            <h1><span>sahara</span><br/>Innovative Solutions for <br/>a Connected World</h1>
        </div>
        </div>
        <div className="bar9"></div>

      
    </div>
  </div>
</div>
    </>
  )
}

export default AboutusBanner