import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import ContactBanner from '../ContactBanner/ContactBanner'
import Contact from '../Contact/Contact'

function ContactPage() {
  return (
    <>
     <div className="wrapper">
    <Navbar/>
    <ContactBanner/>
    <Contact/>
   <Footer/>
    </div>
    </>
  )
}

export default ContactPage