import React, { useEffect, useState } from 'react'
import './CareerAbout.css'
import 'animate.css';
import 'animate.css/animate.min.css';

function CareerAbout() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
   
  }, []);
  return (
    <>
    <div className="container about2">
  <div className="row">
    <div className="col future2">
      <h5  className={` ${animate ? 'animate__animated animate__fadeInLeftBig' : ''}`}>Join Our Team and Shape Your Future</h5>
      <p>At we believe that our people are our greatest asset. We are committed to fostering a dynamic and inclusive work 
        environment where every team member can thrive, innovate, and grow. If you are passionate, driven, and ready to make 
        a difference, we invite you to explore the exciting career opportunities we offer.</p>
    </div>
  </div>
  <div className="row ">
    <div className="col-9 available2">
        <h6>Available Positions</h6><br/>
        <p>There is no vacancies available</p>
    </div>
  </div>
</div>
    </>
  )
}

export default CareerAbout